/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "normalize.css"
import "./src/styles/styles.scss"

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    // Handle anchor links
    const hash = location.hash.replace(/^#(\d)/, "#\\3$1")
    const item = document.querySelector(`${hash}`)
    if (item) {
      const mainNavHeight = 0 // adjust if needed
      window.scrollTo({
        top: item.offsetTop - mainNavHeight,
        behavior: "smooth",
      })
    }
  } else {
    // For normal page navigation, scroll to top
    window.scrollTo(0, 0)
  }
  return true
}
